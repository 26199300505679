@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/colors.scss';

.buttons {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.code {
  background: $white-10;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 20px;
  width: 100%;
  margin: 20px;
}

.content {
  word-break: break-word;
}
