@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.grid {
  display: grid;
  grid-auto-rows: auto;
  gap: 40px;

  @media (min-width: $sm) {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 70px;
  }

  @media (min-width: $md) {
    grid-template-columns: 3fr 2fr;
    gap: 16%;
  }
}

.domain-list {
  position: relative;

  &-tooltip {
    position: absolute;
    top: 26px;
    right: -26px;
  }
}

.azure-input {
  max-width: 400px;
  padding-top: 40px;
}
