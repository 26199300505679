@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.licenses {
  position: relative;
  @media (min-width: $md) {
    display: flex;
  }
}

.license {
  margin-bottom: 8px;
  width: 430px;
  box-sizing: border-box;

  @media (min-width: $md) {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
