@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.block {
  margin-bottom: 20px;
  display: grid;
  grid-template-areas:
    'branch id'
    'version version';
  gap: 20px;
  @media (min-width: $sm) {
    gap: 20px 40px;
  }
  @media (min-width: $md) {
    grid-template-columns: 1fr 220px 1fr;
    gap: 20px;
    grid-template-areas: 'branch id version';
    align-items: flex-start;
  }

  @media (min-width: $lg) {
    gap: 20px 40px;
  }
}

.build-branch {
  grid-area: branch;
}

.build-id {
  grid-area: id;
}

.release-version {
  grid-area: version;
}
