@import '@xq/ui-kit/lib/style.css';

body,
html {
  margin: 0;
  padding: 0;
}

.breadcrumbs {
  margin-top: -40px;
  margin-bottom: 24px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.link {
  text-decoration: none;
}
