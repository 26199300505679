@import '@xq/ui-kit/lib/styles/mixins.scss';

$width: 335px;
$max-width: 453px;

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  min-height: 44px;

  &-buttons {
    display: flex;
    margin-left: 20px;
  }
}

.grid {
  @include scrollbar();
  display: flex;
  align-items: baseline;
  overflow-x: scroll;
  max-height: calc(100vh - 210px);
  min-height: 600px;
}

.column {
  min-width: $max-width;
  margin-right: 20px;
}

.select {
  width: $width;
  margin-bottom: 40px;
}

.pricing {
  margin-bottom: 20px;
  width: 420px;
}

.datepicker {
  margin-bottom: 40px;
  min-width: $width;

  &-wrap {
    display: flex;
    align-items: baseline;
  }
}

.add-column {
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-right: 40px;
}
