@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.container {
  min-width: 900px;
}

.block {
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-gap: 12%;

  @media (min-width: $md) {
    grid-template-columns: 2fr 1fr;
    grid-gap: $gutter-md;
  }

  @media (min-width: $lg) {
    grid-gap: $gutter-lg;
  }
}

.certificate {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 40px;
}
