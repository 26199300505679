@import '@xq/ui-kit/lib/styles/colors.scss';

.status {
  margin-left: -16px;

  &--published {
    @extend .status;
    fill: $green-70;
  }

  &--created {
    @extend .status;
    fill: $gray-40;
  }

  &-wrap {
    display: flex;
    align-items: flex-start;
  }
}
