@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/zIndex.scss';

.layout {
  position: relative;
  padding-top: 55px;
}

.header {
  position: fixed;
  width: 100%;
  z-index: $dropdown-icon-zIndex;
  top: 0;
  left: 0;
  background: $white-0;
}
