@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.table {
  &-status {
    display: flex;
    align-items: center;
    margin-left: -16px;
  }

  &-user {
    display: flex;
  }

  &-username {
    display: flex;
    align-items: center;
  }
}

.statistics {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
