@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.wrap {
  position: relative;
  height: 100%;
  min-height: calc(100vh - 156px);
}
