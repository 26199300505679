@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/zIndex.scss';
@import '@xq/ui-kit/lib/styles/mixins.scss';

.layout {
  position: relative;
  display: flex;
  overflow-x: hidden;
}

.sidemenu {
  background: $white-0;
  z-index: $zIndex-1;
  position: relative;
  margin-right: 40px;
}

.page {
  padding-top: 60px;
  overflow-x: auto;
}
