@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.block {
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-gap: 12%;

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr;
    grid-gap: $gutter-md;
  }

  @media (min-width: $lg) {
    grid-gap: $gutter-lg;
  }
}
