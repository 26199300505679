@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/mixins.scss';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.subheading {
  display: flex;
  margin-top: 8px;
}

.infographics {
  display: flex;
  margin-bottom: 40px;
}

.statistics {
  @include scrollbar();
  margin-bottom: 40px;
  display: flex;
  overflow-x: scroll;
  margin-right: -20px;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.card {
  grid-area: card;
  margin-bottom: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;

  @media (min-width: $md) {
    margin-bottom: 0;
    max-width: auto;
  }
}

.search {
  grid-area: search;
}
