@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/mixins.scss';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.statistics {
  @include scrollbar();
  margin-bottom: 40px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: -20px;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
