@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.grid {
  display: grid;
  gap: 8px;

  @media (min-width: $md) {
    gap: 20px;
  }
}

.users {
  min-width: 500px;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.general {
  max-width: -moz-fit-content;
  max-width: fit-content;
}
