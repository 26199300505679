@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/mixins.scss';

.statistics {
  @include scrollbar();
  margin-bottom: 40px;
  display: flex;
  overflow-x: scroll;
  margin-right: -20px;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.event-icon {
  margin-left: -26px;
  margin-right: 10px;

  svg {
    fill: $gold;
  }
}

.search-wrap {
  display: grid;
  gap: 20px;
  margin-bottom: 40px;

  @media (min-width: $sm) {
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 40px;
  }
}

.link {
  margin-top: 8px;

  span {
    font-size: 12px;
    line-height: 14px;
  }
}
