@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.center {
  text-align: center;
}

.warning-icon {
  margin-left: -24px;
  margin-right: 4px;
}

.license-row {
  position: relative;
  display: flex;
}

.license-rows {
  display: grid;
  gap: 8px;
}

.db-row {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.description-row {
  margin-top: 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
