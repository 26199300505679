@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.grid {
  display: grid;
  gap: 8px;
  margin-bottom: 8px;

  @media (min-width: $md) {
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: $lg) {
    gap: 40px;
    margin-bottom: 40px;
  }

  &-1 {
    @extend .grid;
    position: relative;
    @media (min-width: $md) {
      grid-template-columns: 1fr;
    }
  }

  &-2 {
    @extend .grid;
    @media (min-width: $md) {
      grid-template-columns: 453px 1fr;
    }
  }

  &-3 {
    @extend .grid;
    grid-template-areas:
      'info'
      'users'
      'db';
    @media (min-width: $sm) {
      grid-template-areas:
        'info users'
        'db db';
    }

    @media (min-width: $lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'info users db';
    }
  }

  &-4 {
    @extend .grid;
    position: relative;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info {
  grid-area: info;
}

.users {
  grid-area: users;
}

.db {
  grid-area: db;
}

.alert-input {
  width: 100%;
  margin-top: 20px;
}
